<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="additionalInfoEvents"
        :loading="loading"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :server-items-length="getAdditionalInfoEventsTotalCount"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar color="indigo lighten-5" flat>
            <BackButton />
            <router-link
              :to="{ name: 'InfoConstructorEventsGroupList', params: { id: currentCompanyId } }"
              class="text-decoration-none v-toolbar__title"
            >
              Групи
            </router-link>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-toolbar-title v-if="eventTypeId">
              Події та статуси групи "{{ eventTypeName }} ({{ eventTypeCode }})"
            </v-toolbar-title>
            <v-toolbar-title v-else>Події та статуси</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-if="!eventTypeId"
              v-model="search"
              class="shrink mr-10"
              append-icon="mdi-magnify"
              label="Пошук"
              single-line
              hide-details
              clearable
            ></v-text-field>
            <v-btn v-if="eventTypeId" :to="`${eventTypeId}/${ROUTER_LINKS.INFO_CONSTRUCT_EVENT}`" color="primary" link small>
              Створити подію
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.id="{ item }">
          <router-link
            v-if="eventTypeId"
            :to="`${eventTypeId}/${ROUTER_LINKS.INFO_CONSTRUCTOR_EVENT_CARD}/${item.id}`"
            class="text-decoration-none"
          >
            {{ item.id }}
          </router-link>
          <span v-else>{{ item.id }}</span>
        </template>
        <template v-slot:item.name="{ item }">
          <router-link
            v-if="eventTypeId"
            :to="`${eventTypeId}/${ROUTER_LINKS.INFO_CONSTRUCTOR_EVENT_CARD}/${item.id}`"
            class="text-decoration-none"
          >
            {{ item.name[$currentLocale] }}
          </router-link>
          <span v-else>{{ item.name[$currentLocale] }}</span>
        </template>
        <template v-slot:item.device_type="{ item }">
          {{ getDeviceTitle(item.device_type) }}
        </template>
        <template v-slot:item.packet_event_type="{ item }">
          {{ item.packet_event_type | getPackageTitle }}
        </template>
        <template v-slot:item.description="{ item }">
          {{ item.description[$currentLocale] }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small @click="navigateToInfoEventConstructor(item)" v-bind="attrs" v-on="on" class="mr-2">
                mdi-pencil-outline
              </v-icon>
            </template>
            <span>Редагувати</span>
          </v-tooltip>
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" @click="deleteItem(item)" v-bind="attrs" v-on="on"> mdi-delete-outline </v-icon>
            </template>
            <span>Видалити подію можна, якщо вона не прив'язана до статті</span>
          </v-tooltip>
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small @click="navigateToInfoEventCard(item)" v-bind="attrs" v-on="on"> mdi-eye-outline </v-icon>
            </template>
            <span>Переглянути</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="resetData"> Оновити </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5 justify-center">Подію буде видалено</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteItemConfirm">Так</v-btn>
          <v-btn text @click="closeDelete">Нi</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import resetTableData from '@/mixins/resetTableData'
import ConvertDevicesTypes from '@/mixins/convertDevicesTypes'
import ConvertPackagesTypes from '@/mixins/convertPackagesTypes'
import AwaitingSearch from '@/mixins/constructor/awaitingSearch'
import BackButton from '@/components/common/BackButton.vue'

export default {
  name: 'InfoConstructorEventsList',
  components: { BackButton },

  mixins: [ConvertDevicesTypes, ConvertPackagesTypes, resetTableData, AwaitingSearch],

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
    search: '',
    loading: false,
    clickedPartnerId: null,
    headers: [
      { text: 'ID', align: 'start', sortable: true, value: 'id' },
      { text: 'Назва події', align: 'start', sortable: false, value: 'name.uk' },
      { text: 'Код події', align: 'start', sortable: true, value: 'event_code' },
      { text: 'Протокол', align: 'start', sortable: true, value: 'protocol' },
      { text: 'Тип пристрою', align: 'start', sortable: true, value: 'device_type' },
      { text: 'Тип пакету', align: 'start', sortable: true, value: 'packet_event_type' },
      { text: 'Опис', align: 'start', sortable: false, value: 'short_description.uk', width: 200 },
      { text: '', value: 'actions', sortable: false, width: '125px', align: 'center' },
    ],
    sortBy: 'id',
    sortDesc: false,
    partnersList: [],
    partner: {},
    options: {},
    dialogDelete: false,
    eventId: null,
  }),

  computed: {
    ...mapGetters('infoConstructor', ['getAdditionalInfoEventsTotalCount']),
    ...mapState('infoConstructor', ['additionalInfoEvents']),
    ...mapState('dictionaries', ['machinesTypesDictionary']),

    currentCompanyId() {
      return this.$route.params.id
    },
    eventTypeId() {
      return this.$route.params.eventTypeId || ''
    },
    eventTypeName() {
      return this.$route.query.eventTypeName || ''
    },
    eventTypeCode() {
      return this.$route.query.eventTypeCode || ''
    },
    sortDirection() {
      if (this.options.sortBy.length === 1 && this.options.sortDesc.length === 1) {
        return this.options.sortDesc[0] ? 'desc' : 'asc'
      } else return ''
    },
  },

  watch: {
    options: {
      handler() {
        this.paginateTo()
      },
      deep: true,
    },
  },

  beforeDestroy() {
    this.SET_SELECTED_ADDITIONAL_INFO_EVENT_TYPE({})
    this.SET_ADDITIONAL_INFO_EVENT_CODES([])
  },

  methods: {
    ...mapActions('infoConstructor', ['loadAdditionalInfoEventCodes', 'deleteSelectedAdditionalInfoEvent']),
    ...mapActions('logs', ['displayWarningAlert']),
    ...mapMutations('infoConstructor', ['SET_SELECTED_ADDITIONAL_INFO_EVENT_TYPE', 'SET_ADDITIONAL_INFO_EVENT_CODES']),

    async paginateTo() {
      if (this.currentCompanyId !== 'undefined') {
        this.loading = true
        const params = {
          eventType: this.eventTypeId,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sort: this.options.sortBy[0],
          direction: this.sortDirection,
          search: this.search,
        }
        await this.loadAdditionalInfoEventCodes(params)
        this.loading = false
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },
    navigateToInfoEventCard(item) {
      const eventId = item.id
      const eventTypeId = this.eventTypeId || item.event_type.id
      this.$router.push({
        name: 'InfoConstructorEventCard',
        params: { eventTypeId, eventId },
      })
    },
    navigateToInfoEventConstructor(item) {
      const eventId = item.id
      const eventTypeId = this.eventTypeId || item.event_type.id
      this.$router.push({
        name: 'InfoConstructEvent',
        params: { eventTypeId },
        query: { eventId },
        hash: '#edit',
      })
    },
    deleteItem(item) {
      this.dialogDelete = true
      this.eventId = item.id
    },
    async deleteItemConfirm() {
      this.loading = true
      await this.deleteSelectedAdditionalInfoEvent(this.eventId)
      this.closeDelete()
      await this.paginateTo()
      this.loading = false
    },
    closeDelete() {
      this.dialogDelete = false
    },
  },
}
</script>

<style scoped></style>
